/* CSS for www.vikingcongress.net */

@import "../../../../../../lib/uio2/css/base/base.less";
@import "../../../../../../lib/uio2/css/typography.less";
@import "../../../../../../lib/uio2/css/grid.less";
@import "../../../../../../lib/uio2/css/components.less";
@import "../../../../../../lib/uio2/css/documents.less";
@import "../../../../../../lib/uio2/css/listings.less";
@import "../../../../../../lib/uio2/css/search.less";
@import "../../../../../../lib/uio2/css/frontpage-edit.less";
@import "../../../../../../lib/uio2/css/embedded.less";
@import "../../../../../../lib/uio2/css/print.less";

@import '../../../../../groups/all/src/resources/css/head-language-switch.less';

@import "../../../../../groups/design-uio2/src/resources/css/color-profile/color-profile.less";
@import "../../../../../groups/design-uio2/src/resources/css/color-profile/green-profile.less";

#head-wrapper #head #header a.logo {
    display: flex;
    align-items: center;
    img {
        max-height: 100px;
        padding: 10px 0;
    }
}